<template>
  <v-container id="scouting" fluid tag="section" class="container-full-size">
    <v-data-table
      :headers="headers"
      :items="tableItems"
      :search="search"
      :no-data-text="str['no_data']"
      :no-results-text="str['no_data']"
      class="elevation-1"
      @click:row="openItem"
    >
      <template #top>
        <v-toolbar flat>
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="str['search']" single-line hide-details />
          <v-spacer />
          <v-btn v-if="editPermission" color="success" dark class="mb-2" @click="newItem()">
            {{ str['add'] }}
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.status="{ item }">
        <span :class="[statusDict[item.status].color + '--text']">
          {{ item.status }}
        </span>
      </template>
      <template #item.notes="{ item }">
        <v-icon color="info" dark class="cursor-hover ml-1" @click.stop="showInformation(item.notes)">mdi-information</v-icon>
      </template>
      <template #item.actions="{ item }">
        <v-icon color="success" style="margin-right: 10px" @click.stop="openDialogFeedback(item)">mdi-pencil</v-icon>
        <v-icon small class="mr-0" color="error" @click.stop="deleteItem(item)">mdi-delete</v-icon>
      </template>
      <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" persistent :max-width="itemToEdit ? '950px' : '650px'">
      <v-card>
        <v-card-title>
          <span v-if="!itemToEdit" class="headline">
            {{ str['create_new'] }}
          </span>
          <span v-if="itemToEdit" class="headline">
            {{ str['edit_data'] }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col v-if="itemToEdit" cols="12">
                <v-tabs v-model="tabActive" background-color="transparent" color="secondary" style="margin-top: 0" grow show-arrows>
                  <v-tab v-for="tab in tabs" :key="tab.value">
                    {{ str[tab.label] ? str[tab.label] : tab.label }}
                  </v-tab>
                </v-tabs>
              </v-col>
              <v-col
                v-for="item in filteredData"
                :key="item.id"
                cols="12"
                :style="{
                  'pointer-events': editPermission ? '' : 'none',
                }"
              >
                <v-text-field
                  v-if="item.type === 'input'"
                  v-model="item.value"
                  :label="item.title"
                  :disabled="item.disabled ? true : false"
                />
                <v-textarea
                  v-if="item.type === 'textarea'"
                  v-model="item.value"
                  :label="item.title"
                  :disabled="item.disabled ? true : false"
                  rows="3"
                />
                <v-autocomplete
                  v-if="item.type === 'select'"
                  v-model="item.value"
                  :label="item.title"
                  item-text="label"
                  item-value="value"
                  :items="item.items"
                  :no-data-text="str['no_data']"
                />
              </v-col>
              <v-col v-if="itemToEdit && tabs[tabActive].value === 'feedbacks'">
                <v-data-table
                  :headers="feedbacksHeaders"
                  :items="itemToEdit.feedbacks"
                  :no-data-text="str['no_data']"
                  :no-results-text="str['no_data']"
                  class="elevation-1"
                >
                  <template #item.notes="{ item }">
                    <v-icon color="info" dark class="cursor-hover ml-1" @click.stop="showInformation(item.notes)">mdi-information</v-icon>
                  </template>
                  <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                    {{ pageStart }}-{{ pageStop }} {{ str['of'] }}
                    {{ itemsLength }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="closeDialog">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn v-if="itemToEdit" color="blue" @click="openDialogFeedback(itemToEdit)">
            {{ str['new_register'] }}
          </v-btn>
          <v-btn v-if="editPermission" color="success" @click="saveDialog">
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogFeedback" persistent max-width="650px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ str['new_register'] }}{{ itemToEdit ? ' - ' + itemToEdit.name : '' }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col v-for="item in feedbackData" :key="item.id" cols="12">
                <v-text-field
                  v-if="item.type === 'input'"
                  v-model="item.value"
                  :label="item.title"
                  :disabled="item.disabled ? true : false"
                />
                <v-textarea
                  v-if="item.type === 'textarea'"
                  v-model="item.value"
                  :label="item.title"
                  :disabled="item.disabled ? true : false"
                  rows="3"
                />
                <v-autocomplete
                  v-if="item.type === 'select'"
                  v-model="item.value"
                  :label="item.title"
                  item-text="label"
                  item-value="value"
                  :items="item.items"
                  :no-data-text="str['no_data']"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="closeDialogFeedback">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn color="success" @click="saveDialogFeedback">
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Utils from '@/services/Utils'

export default {
  data() {
    const user = Utils.getUser()
    const config = user && user.configurations ? user.configurations : null
    const editPermission = Utils.hasPermission('scouting_list_edit')
    const headers = [
      { text: window.strings['id'], value: 'id', width: 60 },
      {
        text: window.strings['date'],
        value: 'date',
        align: 'center',
        width: 120,
      },
      {
        text: window.strings['name'],
        value: 'name',
        align: 'center',
        width: 140,
      },
      { text: 'Posição', value: 'position', align: 'center', width: 100 },
      {
        text: window.strings['status'],
        value: 'status',
        align: 'center',
        width: 100,
      },
      {
        text: 'Performance',
        value: 'performance',
        align: 'center',
        width: 120,
      },
      { text: 'Técnica', value: 'technique', align: 'center', width: 100 },
      {
        text: 'Inteligência',
        value: 'intelligence',
        align: 'center',
        width: 120,
      },
      { text: 'Finalização', value: 'finishing', align: 'center', width: 120 },
      { text: 'Passe', value: 'passing', align: 'center', width: 100 },
      { text: 'Força', value: 'strength', align: 'center', width: 100 },
      {
        text: window.strings['notes'],
        value: 'notes',
        align: 'center',
        sortable: false,
        width: 80,
      },
    ]
    const statusList = [
      {
        label: 'Por avaliar',
        value: 'Por avaliar',
        color: 'warning',
      },
      {
        label: 'Contratar',
        value: 'Contratar',
        color: 'success',
      },
    ]
    const skillsList = [
      {
        label: '1',
        value: '1',
      },
      {
        label: '2',
        value: '2',
      },
      {
        label: '3',
        value: '3',
      },
      {
        label: '4',
        value: '4',
      },
      {
        label: '5',
        value: '5',
      },
    ]

    if (editPermission) {
      headers.push({
        text: window.strings['actions'],
        value: 'actions',
        sortable: false,
        align: 'center',
        width: 80,
      })
    }

    return {
      str: window.strings,
      user: user,
      config: config,
      editPermission: editPermission,
      tableItems: [],
      dialog: false,
      search: '',
      headers: headers,
      feedbacksHeaders: [
        { text: window.strings['date'], value: 'date', width: 120 },
        {
          text: 'Performance',
          value: 'performance',
          align: 'center',
          width: 120,
        },
        { text: 'Técnica', value: 'technique', align: 'center', width: 100 },
        {
          text: 'Inteligência',
          value: 'intelligence',
          align: 'center',
          width: 120,
        },
        {
          text: 'Finalização',
          value: 'finishing',
          align: 'center',
          width: 120,
        },
        { text: 'Passe', value: 'passing', align: 'center', width: 100 },
        { text: 'Força', value: 'strength', align: 'center', width: 100 },
        {
          text: window.strings['notes'],
          value: 'notes',
          align: 'center',
          sortable: false,
          width: 80,
        },
      ],
      data: [
        {
          type: 'input',
          id: 'name',
          title: window.strings['name'],
          value: '',
          visible: true,
          required: true,
        },
        {
          type: 'input',
          id: 'position',
          title: 'Posição',
          value: '',
          visible: true,
        },
        {
          type: 'input',
          id: 'team',
          title: 'Equipa',
          value: '',
          visible: true,
        },
        {
          type: 'select',
          id: 'status',
          title: window.strings['status'],
          items: statusList,
          value: '',
          visible: true,
        },
        {
          type: 'textarea',
          id: 'notes',
          title: window.strings['notes'],
          value: '',
          visible: true,
        },
      ],
      feedbackData: [
        {
          type: 'select',
          id: 'performance',
          title: 'Performance',
          items: skillsList,
          value: '',
          visible: true,
        },
        {
          type: 'select',
          id: 'technique',
          title: 'Técnica',
          items: skillsList,
          value: '',
          visible: true,
        },
        {
          type: 'select',
          id: 'intelligence',
          title: 'Inteligência',
          items: skillsList,
          value: '',
          visible: true,
        },
        {
          type: 'select',
          id: 'finishing',
          title: 'Finalização',
          items: skillsList,
          value: '',
          visible: true,
        },
        {
          type: 'select',
          id: 'passing',
          title: 'Passe',
          items: skillsList,
          value: '',
          visible: true,
        },
        {
          type: 'select',
          id: 'strength',
          title: 'Força',
          items: skillsList,
          value: '',
          visible: true,
        },
        {
          type: 'textarea',
          id: 'notes',
          title: window.strings['notes'],
          value: '',
          visible: true,
        },
      ],
      itemToEdit: null,
      statusDict: this.getStatusDict(statusList),
      tabs: [
        {
          label: 'details',
          value: 'details',
        },
        {
          label: 'feedbacks',
          value: 'feedbacks',
        },
      ],
      tabActive: 0,
      feedbackToEdit: null,
      dialogFeedback: false,
    }
  },
  computed: {
    filteredData() {
      return this.data.filter(() => !this.itemToEdit || (this.itemToEdit && this.tabs[this.tabActive].value === 'details'))
    },
  },
  watch: {
    dialog(val) {
      const dialog = document.getElementsByClassName('v-dialog')
      if (dialog && dialog[0]) {
        dialog[0].scrollTo(0, 0)
      }
      val || this.closeDialog()
    },
    dialogFeedback(val) {
      const dialog = document.getElementsByClassName('v-dialog')
      if (dialog && dialog[0]) {
        dialog[0].scrollTo(0, 0)
      }
      val || this.closeDialog()
    },
  },
  beforeMount: function () {
    if (!this.user) {
      return false
    }
    this.getScouting()
  },
  methods: {
    getStatusDict: function (list) {
      const dict = {}
      if (list && list.length) {
        list.forEach(function (item) {
          dict[item.value] = item
        })
      }
      return dict
    },
    getScouting: function () {
      this.tableItems = [
        {
          id: 1,
          name: 'João Silva',
          position: 'Avançado',
          team: 'FC Exemplo',
          notes: 'Avançado habilidoso',
          date: '2023-01-15',
          status: 'Por avaliar',
          performance: 4,
          technique: 5,
          intelligence: 3,
          finishing: 4,
          passing: 3,
          strength: 4,
          feedbacks: [
            {
              date: '2023-01-15',
              performance: 4,
              technique: 5,
              intelligence: 3,
              finishing: 4,
              passing: 3,
              strength: 4,
              notes: 'Bom desempenho geral, especialmente na técnica e finalização.',
            },
            {
              date: '2023-02-15',
              performance: 3,
              technique: 4,
              intelligence: 2,
              finishing: 3,
              passing: 2,
              strength: 3,
              notes: 'Desempenho abaixo da média, precisa melhorar na inteligência tática.',
            },
            {
              date: '2023-03-15',
              performance: 5,
              technique: 5,
              intelligence: 4,
              finishing: 5,
              passing: 4,
              strength: 3,
              notes: 'Exibição excelente, especialmente na técnica e finalização.',
            },
          ],
        },
        {
          id: 2,
          name: 'Mário Oliveira',
          position: 'Médio',
          team: 'SC Exemplo',
          notes: 'Médio versátil',
          date: '2023-04-15',
          status: 'Contratar',
          performance: 4,
          technique: 4,
          intelligence: 5,
          finishing: 3,
          passing: 5,
          strength: 3,
          feedbacks: [
            {
              date: '2023-05-15',
              performance: 4,
              technique: 4,
              intelligence: 5,
              finishing: 3,
              passing: 5,
              strength: 3,
              notes: 'Ótimo controlo de bola e visão de jogo.',
            },
            {
              date: '2023-06-15',
              performance: 3,
              technique: 3,
              intelligence: 4,
              finishing: 2,
              passing: 4,
              strength: 3,
              notes: 'Desempenho razoável, pode melhorar na técnica e finalização.',
            },
            {
              date: '2023-07-15',
              performance: 5,
              technique: 5,
              intelligence: 5,
              finishing: 4,
              passing: 5,
              strength: 4,
              notes: 'Excelente atuação em todas as áreas.',
            },
          ],
        },
        {
          id: 3,
          name: 'Carlos Santos',
          position: 'Defesa',
          team: 'AC Exemplo',
          notes: 'Defesa sólido',
          date: '2023-08-15',
          status: 'Por avaliar',
          performance: 4,
          technique: 3,
          intelligence: 4,
          finishing: 2,
          passing: 3,
          strength: 5,
          feedbacks: [
            {
              date: '2023-09-15',
              performance: 4,
              technique: 3,
              intelligence: 4,
              finishing: 2,
              passing: 3,
              strength: 5,
              notes: 'Boa defesa, especialmente na força física.',
            },
            {
              date: '2023-10-15',
              performance: 3,
              technique: 2,
              intelligence: 3,
              finishing: 1,
              passing: 2,
              strength: 4,
              notes: 'Desempenho abaixo da média, precisa melhorar na técnica.',
            },
            {
              date: '2023-11-15',
              performance: 5,
              technique: 4,
              intelligence: 5,
              finishing: 3,
              passing: 4,
              strength: 5,
              notes: 'Sólido desempenho defensivo, especialmente na inteligência tática.',
            },
          ],
        },
      ]
    },
    newItem() {
      this.setData()
      this.itemToEdit = null
      this.dialog = true
    },
    openItem(item) {
      this.setData(item)
      this.itemToEdit = item
      this.dialog = true
    },
    setData(item) {
      for (let i = 0; i < this.data.length; i++) {
        this.data[i].value = item ? item[this.data[i].id] : ''
      }
    },
    closeDialog() {
      this.dialog = false
    },
    saveDialog() {
      const data = this.getData()
      if (data) {
        this.$isLoading(true)
      }
    },
    getData: function () {
      const data = {}
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].required && !this.data[i].value && this.data[i].type !== 'select') {
          this.$alert(window.strings['fields_to_be_filled'], '', 'warning', Utils.getAlertOptions())
          return false
        }
        data[this.data[i].id] = this.data[i].value
      }
      if (this.itemToEdit) {
        data.id = this.itemToEdit.id
      }
      return data
    },
    deleteItem(item) {
      this.$confirm(
        window.strings['want_delete'] + (item ? ' "' + item.name + '"' : '') + '?',
        '',
        'warning',
        Utils.getAlertOptions(true, true),
      )
        .then(() => {})
        .catch(() => {})
    },
    showInformation: function (message) {
      this.$alert(message, '', 'info', Utils.getAlertOptions())
    },
    openDialogFeedback(item, feedback) {
      this.closeDialog()
      for (let i = 0; i < this.feedbackData.length; i++) {
        this.feedbackData[i].value = feedback ? feedback[this.feedbackData[i].id] : ''
      }
      this.itemToEdit = item
      this.feedbackToEdit = feedback
      this.dialogFeedback = true
    },
    closeDialogFeedback() {
      this.dialogFeedback = false
    },
    saveDialogFeedback() {
      this.closeDialogFeedback()
    },
  },
}
</script>
